@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,800&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hover-underline-effect {
  display: inline-block;
  position: relative;
  padding-bottom: 0;
}

.hover-underline-effect::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 3px;
  background-color: white;
  transition: width 0.25s ease-out;
}

.hover-underline-effect:hover::before {
  width: 100%;
  left: 0;
  right: auto;
}

.hover-underline-effect-black {
  display: inline-block;
  position: relative;
  padding-bottom: 0;
}

.hover-underline-effect-black::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 3px;
  background-color: black;
  transition: width 0.25s ease-out;
}

.hover-underline-effect-black:hover::before {
  width: 100%;
  left: 0;
  right: auto;
}
